var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vue"},[_c('div',{staticClass:"container-vue container"},[_c('h1',[_vm._v("Historique des invitations")]),_c('transition-group',{attrs:{"name":"list-complete"}},_vm._l((_vm.envelopes),function(envelope,index){return _c('div',{key:envelope.node.uuid,staticClass:"list-complete-item"},[(
            index === 0 ||
            (index > 0 &&
              _vm.isShowPlace(
                envelope.node.invitation.lieu.nom,
                _vm.envelopes[index - 1].node.invitation.lieu.nom
              ))
          )?_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(envelope.node.invitation.lieu.nom))])]):_vm._e(),_c('item',{attrs:{"item":envelope},on:{"onSave":_vm.onSaveEnvelope}})],1)}),0),_c('infinite-loading',{ref:"infiniteLoading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.getInvitation},scopedSlots:_vm._u([{key:"no-more",fn:function(){return [_c('span')]},proxy:true},{key:"no-results",fn:function(){return [_c('div',{staticClass:"message"},[_c('h2',[_vm._v("Pas d'invitation dans l'historique.")])])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"message"},[_c('h2',[_vm._v("Opps, something went wrong.")])])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }