<template>
  <div class="vue">
    <div class="container-vue container">
      <h1>Historique des invitations</h1>
      <transition-group name="list-complete">
        <div
          class="list-complete-item"
          v-for="(envelope, index) in envelopes"
          :key="envelope.node.uuid"
        >
          <div
            class="title"
            v-if="
              index === 0 ||
              (index > 0 &&
                isShowPlace(
                  envelope.node.invitation.lieu.nom,
                  envelopes[index - 1].node.invitation.lieu.nom
                ))
            "
          >
            <h2>{{ envelope.node.invitation.lieu.nom }}</h2>
          </div>
          <item v-bind:item="envelope" v-on:onSave="onSaveEnvelope" />
        </div>
      </transition-group>
      <infinite-loading
        @infinite="getInvitation"
        spinner="spiral"
        ref="infiniteLoading"
      >
        <template v-slot:no-more>
          <span></span>
        </template>
        <template v-slot:no-results>
          <div class="message">
            <h2>Pas d'invitation dans l'historique.</h2>
          </div>
        </template>
        <template v-slot:error>
          <div class="message">
            <h2>Opps, something went wrong.</h2>
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import Item from "@/components/HistoriqueItem";
import Graphql from "../utils/graphQl";
import QueryUtils from "../utils/queries/historiqueQuery";
import InvitationMixin from "../utils/invitationMixin";
import QueryMixin from "../utils/queryMixin";
import RedirectMixin from "../utils/redirectionMixin";

export default {
  name: "Historique",

  mixins: [InvitationMixin, QueryMixin, RedirectMixin],

  title() {
    return `${this.title} - ${this.congregationName}`;
  },

  data() {
    return {
      envelopes: [],
      cursorEnvelope: "",
      title: this.$store.state.title,
      token: this.$store.state.token,
      userUuid: this.$store.state.userUuid,
      congregationName: this.$store.state.congregationName,
    };
  },

  components: {
    Item,
  },

  methods: {
    getInvitation: function ($state) {
      QueryUtils.setRead();
      QueryUtils.setVariables(
        this.getQueryVariables(
          "uuid",
          "proclamateurs/",
          this.userUuid,
          this.cursorEnvelope
        )
      );

      // Start GraphQL - Return Envelope
      new Graphql(this.token, QueryUtils).request().then((response) => {
        let result = response.data.data.proclamateur.envelopes.edges;

        if (result.length > 0) {
          this.cursorEnvelope = result[result.length - 1].cursor;

          // Trie
          result = result.sort(
            (a, b) =>
              a.node.invitation.lieu.nom.localeCompare(
                b.node.invitation.lieu.nom
              ) ||
              new Date(a.node.invitation.dateDay) -
                new Date(b.node.invitation.dateDay)
          );

          this.envelopes.push(...result);

          $state.loaded();
        } else {
          $state.complete();
        }
      });
      // End GraphQL
    },

    onSaveEnvelope: function (historiqueItem, uuid, answer) {
      QueryUtils.setUpdate();
      QueryUtils.setVariables(this.setMutableVariables(uuid, answer));

      // Start GraphQL - Mutation Envelope
      new Graphql(this.token, QueryUtils).request().then((response) => {
        if (response.data.data.updateEnvelope.envelope.uuid === uuid) {
          historiqueItem.clear();
          historiqueItem.setResponse(answer);
        }
      });
    },
  },
};
</script>
