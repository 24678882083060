import QueryUtils from "../queryUtils";

const queries = [
  // Read
  `query GetProclamateur( $uuid: ID!, $cursor: String ) {
    proclamateur( id: $uuid ) {
      
      envelopes( after: $cursor, send: true, response_list: [ 1, 2 ] ) {
        edges {
          cursor

          node {
            hour
            response
            ics
            uuid

            invitation {
              dateDay
              hour
              minute
              shift
              
              lieu {
                nom
              }
            }                        
          }
        }
      }
    }
  }`,
  // Update
  `mutation UpdateInvitation( $entity: updateEnvelopeInput! ) {
    updateEnvelope( input: $entity ) {
      envelope {
        uuid
      }
    }
  }`,
  // Delete
  ``,
];

export default new QueryUtils(queries);
